import { acceptHMRUpdate, defineStore } from 'pinia'

export const useGlobalStore = defineStore('dialog', {
  state: () => ({
    show: false,
    type: '',
    title: 'Lorem ipsum',
    text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.',
    action: {},
    notifications: [],
    isPending: false
  }),
  actions: {
    openDialog (type, title, text, action = { name: '', action: Function }) {
      this.show = true
      this.type = type
      this.title = title
      this.text = text
      this.action = action
    },

    close () {
      this.closeDialog()
    },

    async confirm () {
      await this.action.action()
      this.closeDialog()
    },

    closeDialog () {
      this.show = false
      setTimeout(() => {
        this.action = {}
      }, 200)
    },

    openNotify (type, title, text, timeout = 3500, delay = 500) {
      const now = Date.now()
      const recent = this.notifications.filter(n => now - n.timestamp < delay)
      if (recent.some(n => n.type === type && n.title === title && n.text === text)) {
        return null // Another identical notification already exists
      }
      const id = this.notifications.length
      const notification = {
        id,
        type,
        title,
        text,
        timeout,
        show: true,
        close () { this.show = false }
      }
      notification.timestamp = now
      this.notifications.push(notification)

      return { notification, index: id }
    },

    closeNotify (index) {
      this.notifications[index].close()
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot))
}
