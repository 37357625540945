import {acceptHMRUpdate, defineStore} from 'pinia'
import {firebaseDb, getFirebaseData} from '@/apis/index.js'
import {collection, getDocs, limit, onSnapshot, orderBy, query, where} from 'firebase/firestore'
import {cloneDeep} from 'lodash'
import {useGlobalStore} from '@/stores/useGlobalStore.js'

export const useUserStore = defineStore('users', {
  state: () => ({
    users: new Map(),
    user: {},
    userClone: {},
    premium_companies: new Map(),
    company: {},
    companyClone: {},
    listeners: [],
    sortBy: 'createdAt',
    sortByStatus: 'all',
  }),

  getters: {
    sortedUsers (state) {
      const array = Array.from(state.users.values()) || []
      return array.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at))
    },

    usersByStatus (state) {
      const users = state.sortedUsers
      return {
        all: users,
        'subscribed': users.filter(
          (item) => ['pro', 'pro_plus', 'premium'].includes(item.plan)),
        'free': users.filter((item) => item.plan === 'free'),
      }
    },
  },

  actions: {
    fetchLead (uid) {
      this.user = this.users.get(uid)
      return this.user
    },

    async fetchUserProfile (value) {
      value = value.trim()
      const globalStore = useGlobalStore()
      try {
        let queryField = ''

        if (value.includes('@')) {
          queryField = 'email'
          value = value.toLowerCase()
        } else if (value.includes('(')) {
          queryField = 'phone'
        } else {
          queryField = 'uid'
        }

        const q = query(collection(firebaseDb, 'users'),
          where(queryField, '==', value))
        const userSnap = await getDocs(q)
        const data = userSnap.docs[0].data()

        if (data) {
          this.user = cloneDeep(data)
          this.userClone = cloneDeep(data)
          await this.fetchCompanyProfile(data)
          this.router.push('/profile-details')
          return { success: true }
        } else {
          globalStore.openNotify('error', 'Error', 'User document not found')
        }
      } catch (error) {
        globalStore.openNotify('error', 'Error', 'Error getting user document')
      }
    },

    async setUserProfile (uid) {
      const globalStore = useGlobalStore()
      try {
        const response = await getFirebaseData('users',`${uid}`)
        this.user = cloneDeep(response.data)
      } catch (e) {
        globalStore.openNotify('error', 'Error', 'Error getting user document')
      }
    },

    async fetchCompanyProfile (user) {
      const globalStore = useGlobalStore()
      try {
        const response = await getFirebaseData('companies', user.company_uid)
        this.company = cloneDeep(response.data)
        this.companyClone = cloneDeep(response.data)
      } catch (e) {
        globalStore.openNotify('error', 'Error', 'Error getting user document')
      }
    },

    async fetchCompanyCreatorProfile (company) {
      const globalStore = useGlobalStore()
      try {
        const response = await getFirebaseData('users', company.uid)
        this.user = cloneDeep(response.data)
        this.userClone = cloneDeep(response.data)
      } catch (e) {
        globalStore.openNotify('error', 'Error', 'Error getting user document')
      }
    },

    async fetchRecentMembers () {
      // Create a reference to the collection
      const collectionRef = collection(firebaseDb, 'users')

      // Create a query to fetch the last 200 documents
      const q = query(collectionRef, orderBy('created_at', 'desc'), limit(200))

      // Execute the query and retrieve the documents
      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // Access the document data
          const uid = doc.id
          this.users.set(uid, doc.data())
        })
      }).catch((error) => {
        console.log('Error getting documents:', error)
      })
    },


    async fetchPremiumMembers() {
      const collectionRef = collection(firebaseDb, 'companies');
      const q = query(collectionRef, where('plan.type', '!=', 'free'));

      // Add a real-time listener
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const uid = doc.id;
          this.premium_companies.set(uid, doc.data());
        });
      }, (error) => {
        console.log('Error getting documents:', error);
      });
    },

    /**
     * Unsubscribes all firebase listeners added to the current store array
     * @function
     * @returns {undefined}
     */
    unsubscribe () {
      this.listeners.forEach(fn => fn())
    },

  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
