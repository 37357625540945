import {acceptHMRUpdate, defineStore} from 'pinia'
import {getFirebaseData, setFirebaseData} from '@/apis/index.js'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {useUserStore} from '@/stores/useUserStore.js'

export const useAdminStore = defineStore('admin', {
  state: () => ({
    statistics: {
      total_users: 0,
      recent_users: []
    },
    chat_bot: {
      data: '',
      template: ''
    },
    listeners: []
  }),

  getters: {
    user_stats() {
      return [
        {
          name: 'Number of Users',
          value: this.statistics?.total_users || 0
        }, {
          name: 'Last 30 days',
          value: this.filterLast30Days
        }, {
          name: 'Paid Users',
          value: this.companyStats.count
        }, {
          name: 'Average Sale',
          value: this.companyStats.avg_sale
        }
      ]
    },

    filterLast30Days() {
      const today = new Date() // Get today's date
      const thirtyDaysAgo = new Date() // Get the date 30 days ago
      thirtyDaysAgo.setDate(today.getDate() - 30)

      const recent_users = this.statistics?.recent_users.filter(obj => {
        const key = Object.keys(obj)
        const timestamp = new Date(obj[key]) // Convert timestamp to Date object
        return timestamp > thirtyDaysAgo && timestamp <= today
      })
      return recent_users.length
    },

    companyStats() {
      const userStore = useUserStore()
      let sum = 0
      let count = 0
      const rate = {
        pro: 29.99,
        pro_plus: 39.99,
        premium: 59.99
      }
      if (userStore.premium_companies?.size) {
        userStore.premium_companies.forEach(company => {
          if (['stripe', 'apple', 'google'].includes(company.plan.provider)) {
            count++
            sum += rate[company.plan.type]
          }
        })
      }
      const avg_sale = (sum / count).toFixed(2)
      return {
        count,
        avg_sale: `$${avg_sale || 0}`
      }
    },

    paidStats() {
      const userStore = useUserStore()
      const stats = {
        pro: 0,
        pro_plus: 0,
        premium: 0
      }
      let sum = 0
      let count = 0
      const rate = {
        pro: 29.99,
        pro_plus: 39.99,
        premium: 59.99
      }
      if (userStore.premium_companies?.size) {
        userStore.premium_companies.forEach(company => {
          if (['stripe', 'apple', 'google'].includes(company.plan.provider)) {
            count++
            sum += rate[company.plan.type]

            if (company.plan.type === 'pro') {
              stats.pro++
            } else if (company.plan.type === 'pro_plus') {
              stats.pro_plus++
            }
            if (company.plan.type === 'premium') {
              stats.premium++
            }
          }
        })
      }
      let avg_sale = (sum / count).toFixed(2)
      avg_sale = `$${avg_sale || 0}`

      const most_popular = Object.keys(stats).reduce((a, b) => stats[a] > stats[b] ? a : b);

      return [
        {
          name: 'Paid Users',
          value: count || 0
        }, {
          name: 'Average Sale',
          value: avg_sale || 0
        }, {
          name: 'Most Popular Plan',
          value: most_popular
        }, {
          name: 'Professional',
          value: stats.pro || 0
        }, {
          name: 'Professional Plus',
          value: stats.pro_plus || 0
        }, {
          name: 'Premium',
          value: stats.premium || 0
        }
      ]
    }
  },

  actions: {
    async fetchAdminStats() {
      const globalStore = useGlobalStore()
      try {
        const response = await getFirebaseData('admin', 'statistics')
        this.statistics = await this.updateStatistics(response.data)
      } catch (e) {
        globalStore.openNotify('error', 'Error', 'Error getting statistics document')
      }
    },

    async updateStatistics(data) {
      let {
        recent_users,
        total_users
      } = data

      let updated = false

      const today = new Date(); // Get today's date
      const ninetyDaysAgo = new Date(); // Get the date 90 days ago
      ninetyDaysAgo.setDate(today.getDate() - 90);

      const usersToRemove = [];
      const userIdsSet = new Set();

      recent_users.forEach((user, index) => {
        const [userId, timeStamp] = Object.entries(user)[0];
        if (timeStamp <= ninetyDaysAgo) {
          usersToRemove.push(index);
          updated = true
        }
        if (userIdsSet.has(userId)) {
          updated = true
          total_users--;
        } else {
          userIdsSet.add(userId);
        }
      });

      for (let i = usersToRemove.length - 1; i >= 0; i--) {
        recent_users.splice(usersToRemove[i], 1);
      }

      if (updated) {
        await setFirebaseData('admin', 'statistics', {
          recent_users,
          total_users
        })
      }

      return {
        recent_users,
        total_users
      };
    },

    async fetchChatBot() {
      const globalStore = useGlobalStore()
      try {
        const response = await getFirebaseData('admin', 'chat_bot')
        this.chat_bot.data = response.data.data || ''
        this.chat_bot.template = response.data.template || ''
      } catch (e) {
        globalStore.openNotify('error', 'Error', 'Error getting chat_bot document')
      }
    },

    /**
     * Unsubscribes all firebase listeners added to the current store array
     * @function
     * @returns {undefined}
     */
    unsubscribe() {
      this.listeners.forEach(fn => fn())
    }

  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAdminStore, import.meta.hot))
}
