// Import the functions you need from the SDKs you need
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { firebaseDb, storage } from './firebase_config.js'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'

/** Developer created reusable helper functions for firebase actions */

/**
 * Get document or subcollection document from Firebase Firestore.
 *
 * @param {string} collection - The name of the Firestore collection.
 * @param {string} docId - The document ID.
 * @param {string} subCollection - The subcollection name. OPTIONAL.
 * @param {string} subDocId - The subcollection document ID. Required if subCollection is given.
 *
 * @return {Promise<Object>} The document data or an error message.
 */
async function getFirebaseData (
  collection, docId, subCollection = '', subDocId = '') {
  try {
    let docRef
    if (subCollection && subDocId) {
      // Get the subcollection document
      docRef = doc(firebaseDb, collection, docId, subCollection, subDocId)
    } else if (!subCollection && !subDocId) {
      // Get the collection document
      docRef = doc(firebaseDb, collection, docId)
    } else {
      throw new Error(
        'Inconsistent arguments: Either both subCollection and subDocId should be provided or neither.')
    }

    const docSnap = await getDoc(docRef)

    if (!docSnap.exists()) {
      throw new Error(
        `No document found with ID: ${docId} in collection: ${collection}`)
    }

    return { error: false, data: docSnap.data() }
  } catch (error) {
    return { error: true, message: error.message }
  }
}

/**
 * Get live document or subcollection document from Firebase Firestore.
 *
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {string} docId - The document ID.
 * @param {Function} callback - The callback function to handle the document data.
 * @param {string} subCollectionName - The subcollection name. OPTIONAL.
 *
 * @return {function} Firebase unsubscribe function.
 */
function getLiveFirebaseData (
  collectionName, docId, callback, subCollectionName) {
  let ref

  if (subCollectionName) {
    // Get the subcollection document
    ref = collection(doc(firebaseDb, collectionName, docId), subCollectionName)
  } else {
    // Get the collection document
    ref = doc(firebaseDb, collectionName, docId)
  }

  return onSnapshot(
    ref,
    { includeMetadataChanges: true },
    (snapshot) => {
      if (!snapshot.exists && !subCollectionName) {
        throw new Error('No such document!')
      } else if (snapshot?.empty && subCollectionName) {
        throw new Error(
          `${collectionName} ${docId} ${subCollectionName}' No such document!`)
      } else {
        const data = subCollectionName
          ? snapshot?.docs.map((doc) => doc.data())
          : snapshot.data()
        callback(data)
      }
    },
    (error) => {
      throw new Error('Error getting document: ' + error)
    },
  )
}

/**
 * Set document in a Firestore collection or a subcollection.
 *
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {string} docId - The document ID.
 * @param {Object} data - The data to set on the document.
 * @param {string} subCollectionName - The name of the subcollection. OPTIONAL.
 * @param {string} subCollectionDocId - The document ID within the subcollection. OPTIONAL.
 *
 * @return {Promise<Object>} - Returns a promise that resolves to an object with a success or error status.
 */
async function setFirebaseData (
  collectionName, docId, data, subCollectionName = '',
  subCollectionDocId = '') {
  let ref

  if (subCollectionName && subCollectionDocId) {
    // Set the document in the subcollection
    ref = doc(firebaseDb, collectionName, docId, subCollectionName,
      subCollectionDocId)
  } else {
    // Set the document in the collection
    ref = doc(firebaseDb, collectionName, docId)
  }

  try {
    await setDoc(ref, data, { merge: true })
    return { message: 'success', success: true, data }
  } catch (error) {
    console.error(
      `Error setting document in ${subCollectionName || collectionName}:`,
      error)
    return { response: error.message, error: true }
  }
}

/**
 * Update a field in a Firestore document or a subcollection document.
 * The field can be an array.
 *
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {string} docId - The document ID.
 * @param {string} field - The field to update.
 * @param {any} value - The new value for the field.
 * @param {string} subCollectionName - The name of the subcollection. OPTIONAL.
 * @param {string} subCollectionDocId - The document ID within the subcollection. OPTIONAL.
 * @param {boolean} isArray - Whether the field is an array. OPTIONAL.
 * @param {boolean} remove - If the field is an array, whether to remove the value from the array. OPTIONAL.
 *
 * @return {Promise<Object>} - Returns a promise that resolves to an object with a success or error status.
 */
async function updateFirebaseData (
  collectionName, docId, field, value, subCollectionName = '',
  subCollectionDocId = '', isArray = false, remove = false) {
  let ref

  if (subCollectionName && subCollectionDocId) {
    // Update the document in the subcollection
    ref = doc(firebaseDb, collectionName, docId, subCollectionName,
      subCollectionDocId)
  } else {
    // Update the document in the collection
    ref = doc(firebaseDb, collectionName, docId)
  }

  const updateData = {
    [field]: isArray ? (remove
      ? arrayRemove(value)
      : arrayUnion(value)) : value,
  }

  try {
    await updateDoc(ref, updateData)
    return { message: 'success', success: true, value }
  } catch (error) {
    console.error(
      `Error updating document in ${subCollectionName || collectionName}:`,
      error)
    return { response: error.message, error: true }
  }
}

/**
 * Delete a document, a subcollection document, or a field in a document or a subcollection document.
 *
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {string} docId - The document ID.
 * @param {string} field - The field to delete. If omitted, the entire document is deleted.
 * @param {string} subCollectionName - The name of the subcollection. OPTIONAL.
 * @param {string} subCollectionDocId - The document ID within the subcollection. OPTIONAL.
 *
 * @return {Promise<Object>} - Returns a promise that resolves to an object with a success or error status.
 */
async function deleteFirebaseData (
  collectionName, docId, field, subCollectionName, subCollectionDocId) {
  let ref

  if (subCollectionName && subCollectionDocId) {
    // Delete from the document in the subcollection
    ref = doc(firebaseDb, collectionName, docId, subCollectionName,
      subCollectionDocId)
  } else {
    // Delete from the document in the collection
    ref = doc(firebaseDb, collectionName, docId)
  }

  try {
    if (field) {
      // Delete a field
      await updateDoc(ref, { [field]: deleteField() })
    } else {
      // Delete a document
      await deleteDoc(ref)
    }

    return { message: 'success', success: true }
  } catch (error) {
    console.error(`Error deleting document or field in ${subCollectionName ||
    collectionName}:`, error)
    return { response: error.message, error: true }
  }
}

async function getFirebaseCollection(collectionName, callBack,  docID, subcollectionName) {
  try {
    let collectionRef = collection(firebaseDb, collectionName);

    // If subcollectionName is provided, fetch the subcollection
    if (subcollectionName && docID) {
      collectionRef = collection(doc(firebaseDb, collectionName, docID), subcollectionName);
    }

    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const updatedData = querySnapshot.docs.map((doc) => doc.data());
      callBack(updatedData);
    });

    return unsubscribe; // Return unsubscribe function to stop listening for updates
  } catch (error) {
    return {
      success: false,
      message: `Error getting ${subcollectionName ? `${subcollectionName} in ${collectionName}/${docID}` : collectionName}: ${error.message}`,
      error,
    };
  }
}

async function uploadFileToStorage (file, storagePath, progressCallback) {
  const docRef = ref(storage, storagePath)
  try {

    const uploadTask = uploadBytesResumable(docRef, file)

    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      if (progressCallback) {
        progressCallback(progress)
      }
    })

    const snapshot = await uploadTask
    return await getDownloadURL(snapshot.ref)
  } catch (error) {
    console.log('Error uploading image:', error)
    throw error
  }
}

/**
 *  Returns a formatted error message for Firebase authentication errors.
 *
 *  @param {string} errorCode - The error message from Firebase.
 *  @returns {string} - A formatted error message for the specific error.
 */
function firebaseMessage (errorCode) {
  const authErrorMessages = {
    'auth/wrong-password': 'Incorrect password, please try again.',
    'auth/user-not-found': 'No user found with that email address.',
    'auth/email-already-in-use': 'That email address is already in use.',
    'auth/weak-password': 'The password is too weak, please choose a stronger one.',
    'auth/invalid-email': 'The email address is not valid, please enter a valid email address.',
    'auth/too-many-requests': 'Too many unsuccessful login attempts. Please try again later.',
    'auth/popup-closed-by-user': 'Authorization popup closed by user. Please try again.',
  }

  const firestoreErrorMessages = {
    'firestore/not-found': 'The requested resource was not found.',
    'firestore/permission-denied': 'You do not have permission to perform this action.',
    'firestore/invalid-argument': 'The request was invalid or malformed.',
    'firestore/already-exists': 'The requested resource already exists.',
    'firestore/aborted': 'The operation was aborted.',
    'firestore/unavailable': 'The service is currently unavailable.',
    'firestore/cancelled': 'The operation was cancelled.',
    'firestore/resource-exhausted': 'The resource has been exhausted.',
    'firestore/failed-precondition': 'The operation was rejected because the system is not in a state required for the operation.',
    'firestore/out-of-range': 'The operation was attempted past the valid range.',
    'firestore/deadline-exceeded': 'The operation timed out.',
    'firestore/internal': 'An internal error occurred.',
    'firestore/unauthenticated': 'You are not authenticated to perform this action.',
  }

  return authErrorMessages[errorCode] || firestoreErrorMessages[errorCode] ||
    errorCode
}

function calculateDocumentSize (data) {
  if (!data) return 0

  const docNameSize = data.__name__ ? data.__name__.length : 0

  const fieldSize = Object.entries(data).
    reduce((totalSize, [fieldName, fieldValue]) => {
      if (fieldName === '__name__') return totalSize // skip the document name field

      const fieldType = typeof fieldValue
      let fieldValueSize = 0

      if (fieldType === 'string') {
        fieldValueSize = fieldValue.length + 1 // UTF-8 encoded bytes + 1
      } else if (fieldType === 'boolean' || fieldType === 'number') {
        fieldValueSize = 1 // boolean or number takes 1 byte
      } else if (fieldType === 'object' && fieldValue !== null) {
        fieldValueSize = JSON.stringify(fieldValue).length + 1 // object size
      } else {
        fieldValueSize = 1 // null takes 1 byte
      }

      return totalSize + fieldName.length + fieldValueSize // total size + field name size + field value size
    }, 0)

  return docNameSize + fieldSize + 32 // additional 32 bytes
}

export {
  getFirebaseData,
  getLiveFirebaseData,
  setFirebaseData,
  updateFirebaseData,
  deleteFirebaseData,
  getFirebaseCollection,
  firebaseMessage,
  calculateDocumentSize,
  uploadFileToStorage,
}
