// useRoute
import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/routes.js'
import { firebaseAuth } from '@/apis/index.js'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || new Promise((resolve) => {
      setTimeout(() => resolve({ top: 0 }), 300)
    })
  },
})

router.beforeEach(async (to, from, next) => {
  let isAuthorized = !!firebaseAuth.currentUser
  if (to?.meta?.requiresAuth && !isAuthorized) {
    next({ name: 'Auth' })
  } else {
    next()
  }
})

export default router
