import { acceptHMRUpdate, defineStore } from 'pinia'
import { useGlobalStore } from '@/stores/useGlobalStore.js'
import { useUserStore } from '@/stores/useUserStore.js'
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { firebaseAuth, firebaseDb, firebaseMessage } from '@/apis'
import { usePlatform } from '@/composables/usePlatform'
// import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { doc, onSnapshot } from 'firebase/firestore'
import { useSupportStore } from '@/stores/useSupportStore.js'
import { useAdminStore } from '@/stores/useAdminStore.js'

const { isCapacitor } = usePlatform

export const useAuthStore = defineStore('auth', {
  state: () => ({
    admin: {},
    listeners: [],
    googleAccessToken: '',
  }),

  getters: {},

  actions: {

    async fetchAdminProfile (docID) {
      return new Promise((resolve, reject) => {
        const docRef = doc(firebaseDb, 'users', docID)
        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            this.admin = docSnapshot.data()
            resolve(this.admin)
          } else {
            reject(new Error('No such document!'))
          }
        }, (error) => {
          reject(new Error('Error getting document: ' + error))
        })

        this.listeners.push(unsubscribe)
      })
    },

    /**
     * Asynchronously signs in a user using the given email and password.
     * @async
     * @function
     * @param {string} email - The email address to use for the sign-in.
     * @param {string} password - The password to use for the sign-in.
     * @param {string} service - The firebase auth provider to sign in with.
     * @param {boolean} reauthenticate - The firebase auth provider to sign in with.     * @returns {Promise<{uid: *}>}
     *   A promise that resolves with undefined when the sign-in is complete.
     */
    async signInUser (email, password, service, reauthenticate) {
      const globalStore = useGlobalStore()
      try {
        switch (service) {
          case 'email':
            if (reauthenticate) {
              await this.reauthenticateUser(password)
            }
            await signInWithEmailAndPassword(firebaseAuth, email, password)
            break
          case 'google':
            if (isCapacitor) {
              // await this.signInWithGoogleNative(reauthenticate)
            } else {
              const googleProvider = new GoogleAuthProvider()
              await signInWithPopup(firebaseAuth, googleProvider)
            }
            break
          case 'apple':
            if (isCapacitor) {
              // await this.signInWithAppleNative(reauthenticate)
            } else {
              const appleProvider = new OAuthProvider('apple.com')
              await signInWithPopup(firebaseAuth, appleProvider)
            }
            break
          default:
            throw new Error('Unsupported sign-in service')
        }

        return { success: true }
      } catch (error) {
        globalStore.openNotify('error', 'Error Signing in',
          firebaseMessage(error.message))
      }
    },

    async reauthenticateUser (password) {
      const currentUser = firebaseAuth.currentUser
      const credential = EmailAuthProvider.credential(currentUser.email,
        password)
      // Re-authenticate user
      await reauthenticateWithCredential(currentUser, credential)
      return currentUser
    },

    // Capacitor
    /*  async signInWithGoogleNative (reauthenticate) {
        const result = await FirebaseAuthentication.signInWithGoogle()
        const credential = GoogleAuthProvider.credential(
          result.credential.idToken)
        let signInCredential
        if (reauthenticate) {
          const currentUser = firebaseAuth.currentUser
          await currentUser.reauthenticateWithCredential(credential)
        } else {
          signInCredential = await signInWithCredential(firebaseAuth, credential)
        }
        return signInCredential
      },*/

    // Capacitor
    /*    async signInWithAppleNative (reauthenticate) {
          const result = await FirebaseAuthentication.signInWithApple({
            skipNativeAuth: true,
          })
          const provider = new OAuthProvider('apple.com')
          const credential = provider.credential({
            idToken: result.credential?.idToken,
            rawNonce: result.credential?.nonce,
          })

          let signInCredential
          if (reauthenticate) {
            const currentUser = firebaseAuth.currentUser
            await currentUser.reauthenticateWithCredential(credential)
          } else {
            signInCredential = await signInWithCredential(firebaseAuth, credential)
          }

          return signInCredential
        },*/

    /**
     * Asynchronously signs out the current user.
     * @async
     * @function
     * @returns {Promise<undefined>} A promise that resolves with undefined when the sign-out is complete.
     */
    async signOutUser () {
      try {
        const stores = [
          useUserStore(),
        ]

        /* if (Capacitor.isNativePlatform) {
           await FirebaseAuthentication.signOut()
         }*/
        await signOut(firebaseAuth)

        // Unsubscribe listeners and reset states in all stores
        await Promise.all(stores.map(async (store) => {
          if (store.unsubscribe) {
            await store.unsubscribe()
          }
          await store.$reset()
        }))

        // Route user
        this.router.push('/auth')
      } catch (error) {
        const globalStore = useGlobalStore()
        globalStore.openNotify('error', 'Error Signing out',
          firebaseMessage(error.message))
      }
    }
    ,

    /**
     * Asynchronously sends a password reset email to the given email address.
     * @async
     * @function
     * @param {string} email - The email address to send the password reset email to.
     * @returns {Promise<undefined>} A promise that resolves with undefined when the password reset email is sent.
     */
    async resetPassword (email) {
      const globalStore = useGlobalStore()
      try {
        await sendPasswordResetEmail(firebaseAuth, email)
        globalStore.openNotify(
          'success',
          'Success',
          'Please check your email for password reset instructions.')
      } catch (error) {
        globalStore.openNotify(
          'error',
          'Error Resetting Password',
          'There was an issue. Please check your email and try again.')
      }
    },

    /**
     * Sets up a listener to the Firebase authentication state and sets the 🍍Pinia state accordingly, as well as
     * fetching
     * and updating relevant data in the 🍍Pinia store.
     * @function
     * @returns {undefined} Returns undefined.
     */
    async handleAuthStateChange () {
      onAuthStateChanged(firebaseAuth, async (user) => {
        const globalStore = useGlobalStore()

        if (user) {
          console.log('🔥Auth Change')
          const userStore = useUserStore()
          const supportStore = useSupportStore()
          const adminStore = useAdminStore()
          const userUid = user.uid

          // Fetch user data
          try {
            const cred = await this.fetchAdminProfile(userUid)
            const { tradebox_admin } = cred

            if (cred && tradebox_admin) {
              if (this.router.currentRoute.value.fullPath === '/auth') {
                this.router.push('/')
              }
            } else {
              globalStore.openNotify(
                'error',
                'Restricted Area',
                'You do not have clearance for this area.',
              )
              this.router.push('/auth')
            }
          } catch (e) {
            this.router.push('/auth')
            throw new Error('Invalid user' + e)
          }

          // Fetch users
          if (!Object.keys(userStore?.users).length) {
            await userStore.fetchRecentMembers()
          }

          if (!Object.keys(userStore?.premium_companies).length) {
            await userStore.fetchPremiumMembers()
          }
          // fetch support tickets
          if (!Object.keys(supportStore?.support_tickets).length) {
            await supportStore.fetchSupportTickets()
          }
          if (!adminStore.statistics.total_users) {
            await adminStore.fetchAdminStats()
          }
          if (!adminStore.chat_bot.data) {
            await adminStore.fetchChatBot()
          }

        } else {
          // User is signed out
        }
      })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}

