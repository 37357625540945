const routes = [
  // Unrestricted
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/pages/Auth.vue'),
  },
  {
    path: '/',
    component: () => import('@/layouts/Default.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('@/pages/Dashboard.vue'),
      },
      {
        path: 'paid-users',
        name: 'Paid Users',
        component: () => import('@/pages/PaidUsers.vue'),
      },
      {
        path: 'profile-details',
        name: 'Profile Details',
        component: () => import('@/pages/ProfileDetails.vue'),
      },
      {
        path: 'support-tickets',
        name: 'Support Tickets',
        component: () => import('@/pages/SupportTickets.vue'),
      },
      {
        path: 'support-ticket',
        name: 'Support Ticket',
        component: () => import('@/pages/SupportTicket.vue'),
      },
      {
        path: 'content-reports',
        name: 'Content Reports',
        component: () => import('@/pages/ContentReports.vue'),
      },
      {
        path: 'content-report',
        name: 'Content Report',
        component: () => import('@/pages/ContentReport.vue'),
      },
      {
        path: 'chat-bot',
        name: 'Chat Bot',
        component: () => import('@/pages/ChatBot.vue'),
      },
    ],
  },

  // Unrestricted - Page 404
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/404.vue'),
  },
]

export default routes
