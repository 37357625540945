import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import '@/assets/style.css'
import App from '@/App.vue'
import router from '@/router'
import { BaseComponents } from '@/boot'

const app = createApp(App)
const pinia = createPinia()
/**
 *  A Pinia plugin to add the Vue Router instance to the Pinia store
 */
pinia.use(({ store }) => {
  store.router = markRaw(router)
})
// app.use(GoogleMaps)
app.use(BaseComponents)
app.use(pinia)
app.use(router)
app.mount('#app')
