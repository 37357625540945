<script setup>
import { onMounted, watch } from 'vue'
import { useAuthStore } from '@/stores/useAuthStore'
import { useDark, useToggle } from '@vueuse/core'
import GlobalDialog from '@/components/GlobalDialog.vue'
import GlobalNotify from '@/components/GlobalNotify.vue'
import { StatusBar, Style } from '@capacitor/status-bar'
import { usePlatform } from '@/composables/usePlatform.js'

const useAuth = useAuthStore()
const isDark = useDark()
useToggle(isDark)

onMounted(() => {
  useAuth.handleAuthStateChange()
  setStatusBar()
})

watch(isDark, () => {
  if (usePlatform.isCapacitor) {
    setStatusBar()
  }
})

const setStatusBar = async () => {
  if (usePlatform.isCapacitor) {
    if (isDark?.value) {
      await StatusBar.setStyle({ style: Style.Dark })
    } else {
      await StatusBar.setStyle({ style: Style.Light })
    }
  }
}

</script>

<template>
  <div>
    <!-- Main Content View -->
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>

    <!-- Global components -->
    <global-dialog />
    <global-notify />
  </div>
</template>
