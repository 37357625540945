import {acceptHMRUpdate, defineStore} from 'pinia'
import {
  getFirebaseCollection,
  getFirebaseData,
  getLiveFirebaseData,
  setFirebaseData
} from '@/apis/index.js'
import {useUid} from '@/utils'
import {cloneDeep} from 'lodash'
import {useUserStore} from '@/stores/useUserStore'
import {useGlobalStore} from '@/stores/useGlobalStore'

export const useSupportStore = defineStore('support', {
  state: () => ({
    support_tickets: new Map(),
    support_ticket: {},
    support_tickets_by_user: new Map(),
    users_support_tickets: {},
    content_reports: new Map(),
    content_report: {},
    listeners: [],
    sortBy: 'createdAt',
    sortByStatus: 'all'
  }),

  getters: {
    sortedSupportTickets(state) {
      const array = Array.from(state.support_tickets.values()) || []
      return array.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },

    sortedContentReports(state) {
      const array = Array.from(state.content_reports.values()) || []
      return array.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },

    supportTicketsByPlan() {
      const users = this.sortedSupportTickets
      return {
        all: users,
        'subscribed': users.filter((item) => item.plan === 'pro' || item.plan === 'pro_plus' || item.plan === 'premium'),
        'free': users.filter((item) => item.plan === 'free')
      }
    },

    supportTicketsByStatus(state) {
      const tickets = Array.from(state.support_tickets)
      const openTickets = tickets.filter((item) => !item[1].resolved);
      const closedTickets = tickets.filter((item) => item[1].resolved);
      return {
        'open': openTickets.length,
        'closed': closedTickets.length
      }
    },

    ContentReportsByStatus(state) {
      const tickets = Array.from(state.content_reports)
      const openTickets = tickets.filter((item) => !item[1].resolved);
      const closedTickets = tickets.filter((item) => item[1].resolved);
      return {
        'open': openTickets.length,
        'closed': closedTickets.length
      }
    }

  },

  actions: {
    fetchSupportTicketsByUser(uid) {
      return this.support_tickets_by_user.get(uid)
    },

    async fetchCompanyProfile(user) {
      const response = await getFirebaseData('companies', user.company_uid)
      this.company = cloneDeep(response.data)
      this.companyClone = cloneDeep(response.data)
    },

    async fetchSupportTickets() {
      const unsubscribe = await getFirebaseCollection('support_tickets', (data) => {
        if (data) {
          for (const userDoc of data) {
            let uid
            for (const docID in userDoc) {
              this.support_tickets.set(docID, userDoc[docID])
              uid = userDoc[docID].user_uid
              this.support_tickets_by_user.set(uid, userDoc)
              if (this.support_ticket.uid === docID) {
                this.support_ticket = userDoc[docID]
              }
            }
          }
        }
      })
      this.listeners.push(unsubscribe)
    },

    async fetchContentReports() {
      try {
         await getLiveFirebaseData('admin', 'content_reports', (data)=>{
           if (data) {
             for (const docID in data) {
               const report = data[docID];
               this.content_reports.set(docID, report);
             }
           }
         }, '');

        return {success: true};
      } catch (error) {
        console.error('Error fetching content reports:', error);
        return {
          success: false,
          error: error.message
        };
      }
    },

    /**
     * Asynchronously submits a support ticket and updates the corresponding state object.
     * @async
     * @function
     * @param {Object} value - The support ticket object to submit.
     * @param {string} action - The action to perform on the support ticket.
     * @returns {Promise<{message: string, success: boolean}|{message: string, error: true}>} A promise that
     * resolves with a success message and a boolean flag indicating success, or rejects with an error message and a
     * boolean flag indicating failure.
     */
    async submitSupportTicket(value, action) {
      const globalStore = useGlobalStore()
      const userStore = useUserStore()
      const {user} = userStore
      console.log('user', user)
      console.log('value', value)
      let message = null
      let body = null
      let title = null
      switch (action) {
        case 'add':
          message = 'Your support ticket has been submitted. Please allow 1-2 business days for a response.'
          body = `Support Ticket #${value.number} has been created by TradeBox Support.`
          title = 'Support Ticket Created'
          break
        case 'update':
          message = 'The support ticket has been updated.'
          body = `Support Ticket #${value.number} has been updated by TradeBox Support.`
          title = 'Support Ticket Updated'
          break
        case 'message':
          message = 'Your message has been sent.'
          body = `A new message from TradeBox Support has been added to Support Ticket #${value.number}.`
          title = 'New Message from TradeBox Support'
          break
        case 'resolved':
          message = 'The support ticket has been resolved.'
          body = `Support Ticket #${value.number} has been marked as "Resolved" by TradeBox Support.`
          title = 'Support Ticket Resolved'
          break
        case 'reopened':
          message = 'Your support ticket has been reopened.'
          body = `Support Ticket #${value.number} has been reopened by TradeBox Support.`
          title = 'Support Ticket Reopened'
          break
      }
      try {

        await setFirebaseData('support_tickets', `${value.user_uid}`, {[value.uid]: value})

        this.support_tickets[value.uid] = value
        if (action !== 'read') {
          const notification = {
            uid: useUid(),
            company_uid: user.company_uid,
            title,
            doc_uid: value.uid,
            body,
            href: `/support/support-ticket/${value.uid}`,
            type: 'support-ticket',
            badge: 1,
            created_at: Date.now(),
            devices: [user.device]
          }
          console.log('notification', notification)
          await setFirebaseData('companies', `${user.company_uid}`, notification, 'notifications', `${notification.uid}`)
          globalStore.openNotify('success', 'Success', message)
        }
        return {
          message: 'success',
          success: true
        }
      } catch (e) {
        console.error('submitSupportTicket Error', e)
        return {
          message: e.message,
          error: true
        }
      }
    },

    async updateContentStatus(report) {
      await setFirebaseData('admin', 'content_reports', {[report.uid]: report})
    },

    /**
     * Unsubscribes all firebase listeners added to the current store array
     * @function
     * @returns {undefined}
     */
    unsubscribe() {
      this.listeners.forEach(fn => fn())
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSupportStore, import.meta.hot))
}
